import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../utils/dateHelpers';
import ProjetForm from '../../components/projet/ProjetForm';
import {
  CommuneModel, ProjetModel,
  TypeTravauxModel, StatutProjetModel,
  EtablissementModel,
  StageProjetModel,
  InterventionModel
} from '../../data/models';
import { useFetch } from '../../hooks/useFetch';
import env from '../../utils/env';
import Alert from "../../components/Alert";
import "react-datepicker/dist/react-datepicker.css";

const AddProjetPage = () => {

  const navigate = useNavigate();

  const [_projet, setProjet] = useState<ProjetModel>({
    etablissement: {} as EtablissementModel,
    typeIntervention: {} as InterventionModel
  } as ProjetModel);

  const [phases, setPhases] = useState<InterventionModel[]>([]);
  const [typeTravaux, setTypeTravaux] = useState('');
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [successful, setSuccessful] = useState<boolean>(false);

  const { error: postProjetError, data: postProjetResp, sendRequest: postProjet } = useFetch<ProjetModel>(
    `${env.API_URL}/api/projets`,
    {
      method: 'POST',
      payload: _projet
    }
  );

  // Nouveau projet
  useEffect(() => {
    if (postProjetResp) {
      setSuccessful(true);
    }
    if (postProjetError) {
      setError(postProjetError);
    }
  }, [postProjetResp, postProjetError]);

  const { data: typesTravaux, error: loadTypeTravauxError } = useFetch<TypeTravauxModel[]>(
    `${env.API_URL}/api/refs/travaux`
  );
  const { data: etablissements, error: loadEtablisError } = useFetch<EtablissementModel[]>(
    `${env.API_URL}/api/etablissements`
  );
  const { data: statuts, error: loadStatutsError } = useFetch<StatutProjetModel[]>(
    `${env.API_URL}/api/refs/projet/statuts`
  );

  const {
    data: loadPhasesResp,
    sendRequest: loadPhases,
    error: loadPhasesError
  } = useFetch<InterventionModel[]>(
    `${env.API_URL}/api/interventions/${typeTravaux}`,
    { method: 'GET' }
  );

  useEffect(() => {
    if (typeTravaux) {
      loadPhases();
    }
  }, [typeTravaux]);

  useEffect(() => {
    if (loadPhasesResp) {
      setPhases(loadPhasesResp);
    }
    if (!loadPhasesResp && loadPhasesError) {
      setError(loadPhasesError);
    }
  }, [loadPhasesResp]);

  useEffect(() => {
    if (loadTypeTravauxError) { setError(loadTypeTravauxError); }
    if (loadStatutsError) { setError(loadStatutsError); }
  }, [loadTypeTravauxError, loadStatutsError]);

  const setDebutProjet = (date: Date) => {
    setProjet(p => ({
      ...p,
      debut: date
    }))
  }
  const setFinProjet = (date: Date) => {
    setProjet(p => ({
      ...p,
      fin: date
    }))
  }

  const handleOnSubmit = (e: React.MouseEvent) => {
    e.preventDefault();

    let errorMsg = 'Merci de bien vouloir saisir les champs suivants :\n';
    let errors = [];

    if (!_projet.greza) {
      errors.push("Le numéro Gresa est obligatoire");
    }

    if (!_projet.etablissement?.sid) {
      errors.push("L'établissement est obligatoire");
    }
    if (!_projet.typeIntervention?.sid) {
      errors.push("La phase est obligatoire");
    }

    if (isNaN(Number(_projet.avancement))) {
      errors.push("Le taux d'avancement doit être numérique");
    }

    if (errors.length == 0) {
      postProjet();
    } else {
      setError(errorMsg);
      setValidationErrors(errors);
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement
    | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;

    if (name.startsWith('statut-projet-')) {
      let field = 'statut';
      let statut = name.replace(/statut-projet-/gi, "");
      setProjet(p => ({
        ...p,
        [field]: statut
      }))
    }
    else if (name.startsWith('phase-projet-')) {
      let phase = name.replace(/phase-projet-/gi, "");
      setProjet(p => ({
        ...p,
        typeIntervention: {
          ...p.typeIntervention,
          sid: phase
        } as InterventionModel
      }))
    }
    else if (name.startsWith('affectation-')) {
      let field = name.replace(/affectation-/gi, "");
    }
    else if (name === 'etablissement') {
      let grz: string;
      if (etablissements) {
        for (let etab of etablissements) {
          if (etab.sid === value) {
            grz = etab.greza;
            break;
          }
        }
      }

      setProjet(p => ({
        ...p,
        greza: grz,
        etablissement: {
          ...p.etablissement,
          sid: value
        } as EtablissementModel
      }))
    }
    else if (type.startsWith('select') && name.startsWith('typeTravaux')) {
      setPhases([]);
      setTypeTravaux(value);
      setProjet(p => ({
        ...p,
        typeTravaux: value,
        typeIntervention: {} as InterventionModel
      }))
    }
    else {
      setProjet(prevProjet => ({
        ...prevProjet,
        [name]: value
      }));
    }
  };

  const handleClearForm = (e: React.MouseEvent) => {
    e.preventDefault();
  }

  return (
    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">

        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden dark:bg-neutral-800 dark:border-neutral-700">

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 dark:border-neutral-700">
                  <div>
                    <h2 className="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      Création de projet
                    </h2>
                    <p className="text-sm text-gray-600 dark:text-neutral-400">
                      Saisir le formulaire du nouveau projet
                    </p>
                  </div>

                  <div>
                    <div className="inline-flex gap-x-2">
                    </div>
                  </div>
                </div>

                <div className="bg-white p-4 sm:p-7 dark:bg-neutral-800">

                  {(validationErrors.length > 0 || error) && (
                    <Alert
                      type="error"
                      title={error}
                      details={validationErrors}
                    />
                  )}
                  {successful && (
                    <Alert
                      type="success"
                      title="Le projet a bien été enregistré"
                      showLinks={true}
                      linkText='Aller à la page des projets'
                      toLink='/projets'
                    />
                  )}
                  <ProjetForm
                    typesTravaux={typesTravaux}
                    statuts={statuts}
                    phases={phases}
                    etablissements={etablissements}
                    handleFieldChange={handleFieldChange}
                    handleSubmit={handleOnSubmit}
                    handleClearForm={handleClearForm}
                    setDebutProjet={setDebutProjet}
                    setFinProjet={setFinProjet}
                    projet={_projet}
                  />
                </div>

                <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 dark:border-neutral-700">
                  <div></div>
                  <div>
                    <div className="inline-flex gap-x-2">
                      <button type="button"
                        className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-600 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                        onClick={handleOnSubmit}>
                        Enregistrer
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProjetPage;
