import { Link } from 'react-router-dom';
import { formatDate } from '../../utils/dateHelpers';
import { ProjetModel } from '../../data/models';
import ActiveStatus from '../../assets/active.svg';
import WarningStatus from '../../assets/warning.svg';
import DangerStatus from '../../assets/danger.svg';
import { useAuthContext } from '../../contexts/AuthContextProvider';

const dateFormat = 'DD/MM/YYYY';

const ProjetTableRow = (projet: ProjetModel) => {

    const { hasAuthority } = useAuthContext();

    const renderStatutProjet = (): React.JSX.Element => {
        var statusBadgeClass: string = "";
        var statusBadgeIcon: React.JSX.Element = <></>;
        var statusBadgeText: string = "Inconnu";
        let statut = projet?.statut;
        if (statut === "DANS_LES_DELAIS") {
            statusBadgeClass = 'bg-teal-100 text-teal-800 dark:bg-teal-500/10 dark:text-teal-500';
            return (
                <span className={statusBadgeClass + " py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium rounded-full"}>
                    <img src={ActiveStatus} />
                    Dans les délais
                </span>
            );
        }
        if (statut === "EN_AVANCE") {
            statusBadgeClass = 'bg-yellow-100 text-yellow-800 dark:bg-yellow-500/10 dark:text-yellow-500';
            return (
                <span className={statusBadgeClass + " py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium rounded-full"}>
                    <img src={WarningStatus} />
                    En avance
                </span>
            );
        }
        if (statut === "EN_RETARD") {
            statusBadgeClass = 'bg-red-100 text-red-800 dark:bg-red-500/10 dark:text-red-500';
            return (
                <span className={statusBadgeClass + " py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium rounded-full"}>
                    <img src={DangerStatus} />
                    En retard
                </span>
            );
        }
        return (
            <span className={statusBadgeClass + " py-1 px-1.5 inline-flex items-center gap-x-1 text-xs font-medium rounded-full"}>
                {statusBadgeIcon}
                <span>{statusBadgeText}</span>

            </span>
        );
    };

    const avancementStyle = { width: `${projet.avancement}%` };

    return (
        <tr>
            <td className="">
                <div className="px-6 py-1.5 flex items-center gap-x-2">
                    {hasAuthority('EDIT_PROJET', 'EDIT_MON_PROJET') && (
                    <Link to={`/projets/${projet.sid}?edit=infos`}
                        className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                        Editer
                    </Link>
                    )}
                    {hasAuthority('VIEW_ETAPES_PROJET') && (
                    <Link to={`/projets/${projet.sid}/etapes`}
                        className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                        Etapes
                    </Link>
                    )}
                    {hasAuthority('VIEW_FAITS_MARQUANTS_PROJET') && (
                    <Link to={`/projets/${projet.sid}/faits`}
                        className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                        Faits
                    </Link>
                    )}
                    {hasAuthority('VIEW_DECOMPTES_PROJET') && (
                    <Link to={`/projets/${projet.sid}/decomptes`}
                        className="inline-flex items-center gap-x-1 text-sm text-red-600 decoration-2 hover:underline font-medium dark:text-red-500">
                        Décomptes
                    </Link>
                    )}
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    {hasAuthority('VIEW_PROJETS', 'VIEW_MES_PROJETS') ?
                        (
                            <Link to={`/projets/${projet.sid}/view/details`}>
                                <button type="button" className="block" data-hs-overlay="#hs-bg-gray-on-hover-cards">
                                    <span className="block text-sm font-semibold text-red-600 dark:text-red-500 hover:underline">
                                        {projet.greza.toUpperCase()}
                                    </span>
                                </button>
                            </Link>
                        ) : (

                            <button type="button" className="block" data-hs-overlay="#hs-bg-gray-on-hover-cards">
                                <span className="block text-sm font-semibold text-red-600 dark:text-red-500 hover:underline">
                                    {projet.greza.toUpperCase()}
                                </span>
                            </button>
                        )}
                    <span className="block text-sm text-gray-500 dark:text-neutral-500">
                        {projet.typeTravaux}
                    </span>
                </div>
            </td>
            <td className="h-px w-72 whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                        {projet.etablissement?.nom}
                    </span>
                    {/* <span className="block text-sm text-gray-500 dark:text-neutral-500">Human resources</span> */}
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    {renderStatutProjet()}
                </div>
            </td>
            <td className="h-px w-72 whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="block text-sm font-semibold text-gray-800 dark:text-neutral-200">
                        {projet.etablissement?.commune?.nom}
                    </span>
                    <span className="block text-sm text-gray-500 dark:text-neutral-500">
                        {projet.etablissement?.province?.nom}
                    </span>
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {formatDate(projet.debut, dateFormat)}
                    </span>
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    <span className="text-sm text-gray-500 dark:text-neutral-500">
                        {projet.fin ? formatDate(projet.fin, dateFormat) : ''}
                    </span>
                </div>
            </td>
            <td className="size-px whitespace-nowrap">
                <div className="px-6 py-3">
                    <div className="flex items-center gap-x-3">
                        <span className="text-xs text-gray-500 dark:text-neutral-500">
                            {`${projet.avancement}%`}
                        </span>
                        <div className="flex w-full h-1.5 bg-gray-200 rounded-full overflow-hidden dark:bg-neutral-700">
                            <div className="flex flex-col justify-center overflow-hidden bg-gray-800 dark:bg-neutral-200"
                                role="progressbar" style={avancementStyle}
                                aria-valuenow={projet.avancement} aria-valuemin={0} aria-valuemax={100}>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
            
        </tr>
    );
};

export default ProjetTableRow;
