import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../contexts/AuthContextProvider';
import { useFetch } from '../../hooks/useFetch';
import LogInForm from '../../components/login/LogInForm';
import env from '../../utils/env';
import logo from '../../cgi-management.jpg';

import "preline/preline";
import { IStaticMethods } from "preline/preline";
import Alert from '../../components/Alert';
declare global {
  interface Window {
    HSStaticMethods: IStaticMethods;
  }
}

type ErrorMsg = { message: string; statusText: string; data: string };

type ErrorPageProps = { message: string };

type LoginResponse = { token: string };

const LoginPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();

  const { authState, updateAuthState } = useAuthContext();
  const { token, isLogged } = authState;

  const loginUrl = `${env.API_URL}/api/login`;
  const payload = { username, password } as const;

  useEffect(() => {
    if (token && isLogged) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    window.HSStaticMethods.autoInit();
  }, [location.pathname]);

  const {
    data: loginResponse,
    sendRequest: sendLoginRequest,
    nullApiResponse: nullLoginResponse,
    error: loginError,
    nullResponseError: nullLoginError
  } = useFetch<LoginResponse>(loginUrl, {
    method: 'POST',
    payload: payload
  });

  // Send login request and receive response
  useEffect(() => {
    if (loginResponse && loginResponse.token) {
      updateAuthState(loginResponse.token);
      nullLoginResponse();
      navigate("/");
    }
  }, [
    loginResponse
  ]);

  // Login error handling
  useEffect(() => {
    if (loginError) {
      setError("Identifiant et/ou mot de passe erroné(s)");
      nullLoginError();
    }
  }, [loginError]);

  const handleLogInClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setError('');
    sendLoginRequest();
  };

  const clearInputs = () => {
    setUsername('');
    setPassword('');
  };

  return (
    <div id="content" className="w-full max-w-md mx-auto p-6">
      <div className="mt-7 bg-white border border-gray-200 rounded-xl shadow-sm dark:bg-neutral-900 dark:border-neutral-700">
        <div className="p-4 sm:p-7">
          <div className="text-center">
            {error && (
              <Alert
                type="error"
                title={error}
              />
            )}
          </div>

          <div className="mt-5">
            <div className="text-center">
              <Link className="flex-none rounded-xl text-xl inline-block font-semibold focus:outline-none focus:opacity-80"
                to="#">
                <img src={logo} width="150" alt="logo" />
              </Link>
            </div>
            <div
              className="py-3 flex items-center text-xs text-gray-400 uppercase before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:text-neutral-500 dark:before:border-neutral-600 dark:after:border-neutral-600">
            </div>

            <LogInForm
              setUsername={setUsername}
              setPassword={setPassword}
              handleLogInClick={handleLogInClick} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
